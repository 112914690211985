import axios from "axios";
import store from "redux/store";

const baseURL = process.env.REACT_APP_API_VEHICLE;
const apikey = process.env.REACT_APP_API_KEY_VEHICLE;
const apiSecret = process.env.REACT_APP_API_SECRET_VEHICLE;

const config = {
  headers: {
    "Content-Type": "application/json",
    "api-key": apikey,
    "api-secret": apiSecret,
    Authorization: "",
  },
};

function getToken() {
  let state = store.getState();
  let authToken = state.Login.authToken;
  return authToken;
}

export function addTheCar(formData: any) {
  config.headers.Authorization = getToken();
  return axios.post(baseURL + "/addVehicle", formData, config);
}

export function getOnGoingvehicles(
  page: number,
  items: number,
  exportValue?: boolean
) {
  config.headers.Authorization = getToken();
  let url = exportValue
    ? `/onGoingSale?export=true`
    : `/onGoingSale?page=${page}&items=${items}`;
  return axios.get(baseURL + url, config);
}

export function getAuctionedvehicles(page: number, items: number) {
  config.headers.Authorization = getToken();
  return axios.get(
    baseURL + `/corporateListing?page=${page}&items=${items}`,
    config
  );
}

export function getDocuments(vehicleId: string) {
  config.headers.Authorization = getToken();
  return axios.get(baseURL + `/document?vehicleId=${vehicleId}`, config);
}

export function reduceListedPrice(data: any) {
  config.headers.Authorization = getToken();
  return axios.post(`${baseURL}/reduceListedPrice`, data, config);
}

export function getSellingHistory(
  page: number,
  items: number,
  exportValue?: boolean
) {
  config.headers.Authorization = getToken();
  let url = exportValue
    ? `/sellingHistory?export=true`
    : `/sellingHistory?page=${page}&items=${items}`;
  return axios.get(baseURL + url, config);
}

export function getRejectedQuotes(
  page: number,
  items: number,
  exportValue?: boolean
) {
  let newConfig = JSON.parse(JSON.stringify(config));
  newConfig.headers.Authorization = getToken();
  let url = exportValue
    ? `/rejectedVehicles?export=true`
    : `/rejectedVehicles?page=${page}&items=${items}`;
  return axios.get(baseURL + url, config);
}

export function sellAtOfferPrice(payload: any) {
  let newConfig = JSON.parse(JSON.stringify(config));
  newConfig.headers.Authorization = getToken();
  return axios.post(`${baseURL}/sellAtOfferPrice`, payload, newConfig);
}

export function getDocument(vehicleId: string) {
  config.headers.Authorization = "";
  return axios.get(`${baseURL}/document?vehicleId=${vehicleId}`, config);
}

export function GetColor() {
  config.headers.Authorization = "";
  return axios.get(`${baseURL}/color`, config);
}

export function GetModel(id: string) {
  config.headers.Authorization = "";
  return axios.get(
    `${baseURL}/resourceList?resourceType=model&make=${id}`,
    config
  );
}

export function GetMake() {
  config.headers.Authorization = "";
  return axios.get(`${baseURL}/resourceList?resourceType=make`, config);
}

export function GetYear(model: string, id: string) {
  config.headers.Authorization = "";
  return axios.get(
    `${baseURL}/resourceList?resourceType=year&model=${model}&make=${id}`,
    config
  );
}

export function GetVariant(model: string, makeId: string, date: Date | null) {
  config.headers.Authorization = "";
  return axios.get(
    `${baseURL}/resourceList?resourceType=variantDetail&mfgDate=${date?.toISOString()}&model=${model}&make=${makeId}`,
    config
  );
}

export function getURL(name: string) {
  let newConfig = JSON.parse(JSON.stringify(config));
  newConfig.headers.Authorization = getToken();
  return axios.get(baseURL + "/generateUploadUrl?key=" + name, newConfig);
}

export function uploadDocument(responseData: any, file: any) {
  let formData = new FormData();
  let key: string;
  for (key in responseData.fields) {
    formData.append(key, responseData.fields[key]);
  }
  let newConfig = JSON.parse(JSON.stringify(config));
  formData.append("file", file);
  newConfig.headers["Content-Type"] = "multipart/form-data";
  newConfig.headers.Authorization = "";
  return axios.post(responseData.url, formData, newConfig);
}
