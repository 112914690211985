import { ADDED_NEW_CAR, ONGOING_DATA } from "./types";

export function reloadList() {
  return {
    type: ADDED_NEW_CAR,
    payload: true,
  };
}

export function onGoingData(data:any) {
  return {
    type: ONGOING_DATA,
    payload: data,
  };
}
