import React, { useEffect, useState, useRef } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import { TableRow, Link, TablePagination } from "@material-ui/core";
import { numberWithCommas } from "shared/utils/formatStrings";
import { emptyOnGoingSale, OnGoingSaleModel } from "shared/models/OnGoing";
import { getAuctionedvehicles } from "shared/services/vehicle";
import { Loader } from "shared/components/Loader";
import { CarDetails } from "shared/components/CarDetailsModal";

export function BuyACar() {
  const [vehicleData, setVehicleData] = useState<OnGoingSaleModel[]>([]);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<any>(emptyOnGoingSale);
  // const [priceBreakdownModal, setPriceBreakdownModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState<any>(0);

  useEffect(() => {
    fetchAuctionedCars();
  }, [page, rowsPerPage]);

  function fetchAuctionedCars() {
    getAuctionedvehicles(page + 1, rowsPerPage)
      .then((response) => {
        let sortedData: any = response.data.items.sort((a: any, b: any) => {
          return new Date(a.created).valueOf() - new Date(b.created).valueOf();
        });
        setVehicleData(sortedData);
        setCount(response.data.itemsSummary);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleCloseDetails() {
    setShowDetails(false);
  }

  if (!loading) {
    if (vehicleData.length > 0) {
      return (
        <div className="layout--cars--listing pb-5 px-3">
          <CarDetails
            openModal={showDetails}
            details={selectedRowData}
            handleClose={handleCloseDetails}
          />
          <div className="layout--welcome py-4 mt-5">
            <h2 className="title">
              {count.auction} {count.auction > 1 ? "Cars are " : "Car is "}
              available through auction
            </h2>
          </div>
          <Paper className="mt-4">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>#</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Make &amp; Model
                    </TableCell>
                    {/* <TableCell style={{ fontWeight: "bold" }}>
                      Fuel Type
                    </TableCell> */}
                    <TableCell style={{ fontWeight: "bold" }}>Year</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Kms</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Color</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Owner</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Registration Number
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Selling Format
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Status Of Car
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Added On
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Sale Value
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vehicleData.map((row, index: number) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    let status = row.status.split("_").join(" ");
                    let format =
                      row.sellingFormat == "auction"
                        ? row.sellingFormat
                        : "Park & Sell";
                    let price: any = "-";
                    // let options = {
                    //   year: "numeric",
                    //   month: "long",
                    //   day: "numeric",
                    // };
                    let date = new Date(row.created).toLocaleDateString(
                      "en-US"
                    );
                    let year = new Date(row.year).getFullYear();
                    if (
                      row.sellingFormat.toLowerCase() == "auction" &&
                      row.price
                    ) {
                      if (row.auction) {
                        price = numberWithCommas(row.auction.driveHomePrice);
                      } else {
                        price = "-";
                      }
                    } else if (row.sellingFormat != "auction" && row.price) {
                      price = numberWithCommas(row.price.driveHomePrice);
                    }
                    let rcNumber = row.rcDetails.registrationNumber.substring(
                      0,
                      4
                    );
                    return (
                      <TableRow hover title="click to view details" key={index}>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          onClick={(event) => {
                            setShowDetails(true);
                            setSelectedRowData(row);
                          }}
                        >
                          {(page + 1 - 1) * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell
                          className="text-capitalize"
                          onClick={(event) => {
                            setShowDetails(true);
                            setSelectedRowData(row);
                          }}
                        >
                          {`${row.make.toLowerCase()} ${row.model.toLowerCase()}`}
                        </TableCell>
                        {/* <TableCell
                          align="left"
                          className="text-capitalize"
                          onClick={(event) => {
                            setShowDetails(true);
                            setSelectedRowData(row);
                          }}
                        >
                          {row.fuelType}
                        </TableCell> */}
                        <TableCell
                          align="left"
                          className="text-capitalize"
                          onClick={(event) => {
                            setShowDetails(true);
                            setSelectedRowData(row);
                          }}
                        >
                          {year}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="text-capitalize"
                          onClick={(event) => {
                            setShowDetails(true);
                            setSelectedRowData(row);
                          }}
                        >
                          {row.totalKms.user
                            ? numberWithCommas(row.totalKms.user)
                            : "-"}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="text-capitalize"
                          onClick={(event) => {
                            setShowDetails(true);
                            setSelectedRowData(row);
                          }}
                        >
                          {row.color.rcColor.toLowerCase()}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="text-capitalize"
                          onClick={(event) => {
                            setShowDetails(true);
                            setSelectedRowData(row);
                          }}
                        >
                          {row.numberOfOwners}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="text-capitalize"
                          onClick={(event) => {
                            setShowDetails(true);
                            setSelectedRowData(row);
                          }}
                        >
                          {rcNumber}XXXX
                        </TableCell>{" "}
                        <TableCell
                          align="left"
                          className="text-capitalize"
                          onClick={(event) => {
                            setShowDetails(true);
                            setSelectedRowData(row);
                          }}
                        >
                          {format}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="text-capitalize"
                          onClick={(event) => {
                            setShowDetails(true);
                            setSelectedRowData(row);
                          }}
                        >
                          {status}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="text-capitalize"
                          onClick={(event) => {
                            setShowDetails(true);
                            setSelectedRowData(row);
                          }}
                        >
                          {date}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="text-capitalize font-weight-bold"
                        >
                          {price}
                          <Link
                            component="button"
                            className="ml-2 link-button"
                            hidden={price != "-" && price != "" ? false : true}
                            onClick={() => {
                              setSelectedRowData(row);
                              // setPriceBreakdownModal(true);
                              setShowDetails(false);
                            }}
                          >
                            View Price Breakdown
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25]}
              component="div"
              count={count.total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      );
    } else {
      return (
        <div className="mt-4 text-center">No vehicles on sale currently</div>
      );
    }
  } else {
    return (
      <div>
        <Loader />
      </div>
    );
  }
}
function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
