import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import {
  getOnGoingvehicles,
  getSellingHistory,
  getDocument,
} from "shared/services/vehicle";
import {
  TableRow,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Link,
  TablePagination,
} from "@material-ui/core";
import { numberWithCommas } from "shared/utils/formatStrings";
import { CarDetails } from "shared/components/CarDetailsModal";
import { emptyOnGoingSale, OnGoingSaleModel } from "shared/models/OnGoing";
import { connect } from "react-redux";
import * as subscriptionAction from "redux/actions/subscriptions";
import { Vehicle } from "shared/models/Vehicle";
import { vehicleDetails } from "shared/models/VehicleDetails";
import { Purchase } from "shared/models/Transaction";
import { Loader } from "shared/components/Loader";
import GetAppIcon from "@material-ui/icons/GetApp";
import createXLData from "shared/utils/createXLJson";

type Props = {
  addedNewCar: boolean;
  reloadList: any;
};

function SellingHistory(props: Props) {
  const [vehicleData, setVehicleData] = useState<any[]>([]);
  const [sortBy, setSortBy] = useState(0);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<any>(emptyOnGoingSale);
  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [count, setCount] = useState<any>(0);

  // useEffect(() => {
  //   fetchCarsList();
  // }, []);

  useEffect(() => {
    if (props.addedNewCar == true) {
      fetchCarsList();
      props.reloadList(false);
    }
  }, [props.addedNewCar]);

  useEffect(() => {
    fetchCarsList();
  }, [page, rowsPerPage]);

  function fetchCarsList() {
    getSellingHistory(page + 1, rowsPerPage)
      .then((response) => {
        setVehicleData(response.data.items);
        setCount(response.data.itemsSummary);
        setLoading(false);
        response.data.items.forEach((items: any) => {});
      })
      .catch(() => {
        setLoading(false);
      });
  }

  // function fetchDocument(id: string) {
  //   getDocument(id).then((docData) => {
  //     console.log("success");
  //     window.open();
  //   });
  // }

  function handleCloseDetails() {
    setShowDetails(false);
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function downloadToExcel() {
    getSellingHistory(0, 0, true).then((res) => {
      createXLData(res.data.items, "Sold Car History");
    });
  }

  if (!loading) {
    if (vehicleData.length > 0) {
      return (
        <div className="layout-ongoing-sale">
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<GetAppIcon />}
            className="download-btn mt-3"
            onClick={() => {
              downloadToExcel();
            }}
          >
            Download
          </Button>
          <CarDetails
            openModal={showDetails}
            details={selectedRowData}
            handleClose={handleCloseDetails}
          />
          {/* <div className="d-flex my-3 mb-5"> */}

          {/* <div className="d-flex align-items-center mr-5">
            <span className="mr-3" style={{ fontWeight: "bold" }}>
              Selling Format
            </span>
            <FormControl variant="outlined" size="small" style={{ width: 155 }}>
              <Select
                value={sellingFormat}
                onChange={(e: any) => {
                  setSellingFormat(parseInt(e.target.value));
                }}
              >
                <MenuItem value={0}>Show All</MenuItem>
                <MenuItem value={1}>Park & Sell</MenuItem>
                <MenuItem value={2}>Auction</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="d-flex align-items-center mr-5">
            <span className="mr-3" style={{ fontWeight: "bold" }}>
              Payment Status
            </span>
            <FormControl variant="outlined" size="small" style={{ width: 155 }}>
              <Select
                value={status}
                onChange={(e: any) => {
                  setStatus(parseInt(e.target.value));
                }}
              >
                <MenuItem value={0}>Show All</MenuItem>
                <MenuItem value={1}>Under Refurbishment</MenuItem>
                <MenuItem value={2}>Auction</MenuItem>
                <MenuItem value={3}>On Sale</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="d-flex align-items-center mr-5">
            <span className="mr-3" style={{ fontWeight: "bold" }}>
              RC Transfer Status
            </span>
            <FormControl variant="outlined" size="small" style={{ width: 155 }}>
              <Select
                value={status}
                onChange={(e: any) => {
                  setStatus(parseInt(e.target.value));
                }}
              >
                <MenuItem value={0}>Show All</MenuItem>
                <MenuItem value={1}>Under Refurbishment</MenuItem>
                <MenuItem value={2}>Auction</MenuItem>
                <MenuItem value={3}>On Sale</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="d-flex align-items-center ml-auto">
            <span className="mr-3" style={{ fontWeight: "bold" }}>
              Sort by
            </span>
            <FormControl
              variant="outlined"
              size="small"
              style={{ width: "auto" }}
            >
              <Select
                value={sortBy}
                onChange={(e: any) => {
                  setSortBy(parseInt(e.target.value));
                }}
              >
                <MenuItem value={0}>Latest</MenuItem>
                <MenuItem value={1}>Price: High to Low</MenuItem>
                <MenuItem value={2}>Price: Low to High</MenuItem>
              </Select>
            </FormControl>
          </div>
        */}
          {/* </div> */}
          <Paper className="px-0 mt-4">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>#</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Make & Model
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Sale Format
                    </TableCell>{" "}
                    <TableCell style={{ fontWeight: "bold" }}>
                      Sale Value
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Likes</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Views</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Test Drive
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Sold On
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Payment Status
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      RC Transfer Status
                    </TableCell>{" "}
                    {/* <TableCell style={{ fontWeight: "bold" }}>Actions</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vehicleData.map((row, index: number) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    let format =
                      row.sellingFormat == "auction"
                        ? row.sellingFormat
                        : "Park & Sell";
                    let price: any = "-";
                    let options: any = {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    };
                    let date =
                      row.sellingFormat.toLocaleString() != "auction"
                        ? new Date(row.dates.purchaseStart).toLocaleDateString(
                            "en-US",
                            options
                          )
                        : new Date(
                            row.dates.auctionCompleted
                          ).toLocaleDateString("en-US", options);

                    if (
                      row.sellingFormat.toLocaleString() == "auction" &&
                      row.auction
                    ) {
                      price = row.auction.driveHomePrice
                        ? numberWithCommas(row.auction.driveHomePrice)
                        : "-";
                    } else if (
                      row.sellingFormat.toLocaleString() != "auction" &&
                      row.price
                    ) {
                      price = row.price.driveHomePrice
                        ? numberWithCommas(row.price.driveHomePrice)
                        : "-";
                    }
                    return (
                      <TableRow hover title="click to view details" key={index}>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          onClick={(event) => {
                            setShowDetails(true);
                            setSelectedRowData(row);
                          }}
                        >
                          {(page + 1 - 1) * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell
                          className="text-capitalize"
                          onClick={(event) => {
                            setShowDetails(true);
                            setSelectedRowData(row);
                          }}
                        >
                          {row.make.toLowerCase() +
                            " " +
                            row.model.toLowerCase()}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="text-capitalize"
                          onClick={(event) => {
                            setShowDetails(true);
                            setSelectedRowData(row);
                          }}
                        >
                          {format}
                        </TableCell>{" "}
                        <TableCell align="left" className="text-capitalize">
                          {price}
                        </TableCell>{" "}
                        <TableCell
                          align="left"
                          className="text-capitalize"
                          onClick={(event) => {
                            setShowDetails(true);
                            setSelectedRowData(row);
                          }}
                        >
                          {row.count?.favourite ? row.count.favourite : 0}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="text-capitalize"
                          onClick={(event) => {
                            setShowDetails(true);
                            setSelectedRowData(row);
                          }}
                        >
                          {row.count?.view ? row.count.view : 0}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="text-capitalize"
                          onClick={(event) => {
                            setShowDetails(true);
                            setSelectedRowData(row);
                          }}
                        >
                          {row.count?.testDrive ? row.count.testDrive : 0}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="text-capitalize"
                          onClick={(event) => {
                            setShowDetails(true);
                            setSelectedRowData(row);
                          }}
                        >
                          {date}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="text-capitalize  font-weight-bold"
                          onClick={(event) => {
                            setShowDetails(true);
                            setSelectedRowData(row);
                          }}
                        >
                          {row.paymentStatus
                            ? row.paymentStatus.split("_").join(" ")
                            : "NA"}
                        </TableCell>{" "}
                        <TableCell
                          align="left"
                          className="text-capitalize"
                          onClick={(event) => {
                            setShowDetails(true);
                            setSelectedRowData(row);
                          }}
                        >
                          {row.rcTransferStatus
                            ? row.rcTransferStatus.split("_").join(" ")
                            : "NA"}
                        </TableCell>{" "}
                        {/* <TableCell
                        align="left"
                        className="text-capitalize"
                        title="click to reduce price"
                      >
                        <Link
                          className="link-button"
                          component="button"
                          hidden={
                            row.price &&
                            row.paymentStatus.toLowerCase() == "completed"
                              ? false
                              : true
                          }
                          onClick={() => {
                            fetchDocument(row.id);
                          }}
                        >
                          Download Document
                        </Link>
                        <Link
                          className="link-button"
                          component="button"
                          hidden={
                            row.price &&
                            row.paymentStatus.toLowerCase() != "completed"
                              ? false
                              : true
                          }
                          onClick={() => {
                            fetchDocument(row.id);
                          }}
                        >
                          Download Receipt
                        </Link>
                      </TableCell> */}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25]}
              component="div"
              count={count.total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      );
    } else {
      return <div className="mt-4 text-center">No vehicles sold</div>;
    }
  } else {
    return (
      <div>
        {" "}
        <Loader />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    addedNewCar: state.Subscriptions.addedNewCar,
  };
};

export default connect(mapStateToProps, subscriptionAction)(SellingHistory);
