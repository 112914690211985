import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogProps,
  DialogContentText,
  Button,
  FormGroup,
  Input,
} from "@material-ui/core";
import { vehicleDetails } from "shared/models/VehicleDetails";
import { sendOTP } from "shared/services/customer";
import { reduceListedPrice } from "shared/services/vehicle";
import { numberWithCommas } from "shared/utils/formatStrings";
import { OnGoingSaleModel } from "shared/models/OnGoing";
import { connect } from "react-redux";
import * as subscriptionAction from "redux/actions/subscriptions";
import { ActivityTracker } from "shared/services/activityTracker";

type Props = {
  openModal: boolean;
  details: OnGoingSaleModel;
  handleClose: any;
  reloadList: any;
  userData: any;
};

function ReducePriceModal(props: Props) {
  const [otpRecieve, setOTPRecieved] = useState(false);
  const [newPrice, setNewPrice] = useState(0);
  const [otpValue, setOTPValue] = useState("");
  const [errors, setErrors] = useState({
    price: "",
    otp: "",
    post: "",
  });
  let otp1: any = React.createRef();
  let otp2: any = React.createRef();
  let otp3: any = React.createRef();
  let otp4: any = React.createRef();
  const [otpLoader, setOtpLoader] = useState(false);
  const [priceLoader, setPriceLoader] = useState(false);

  const validateForm = (errors: any) => {
    let valid = true;
    Object.values(errors).forEach((val: any) => {
      val.length > 0 && (valid = false);
    });
    return valid;
  };

  function sendOtp() {
    setOtpLoader(true);
    sendOTP()
      .then((response) => {
        let errorList = errors;
        errorList.otp = "";
        setErrors(errorList);
        setOtpLoader(false);
        setOTPRecieved(true);
      })
      .catch((error) => {
        //TODO:if something wrong show message

        console.log(error);
        setOtpLoader(false);
        let errorList = errors;
        if (error.request.status == 0) {
          errorList.otp = "uh oh ! No internet try again";
        } else {
          errorList.otp = "uh oh ! Something went wrong, try again";
        }
        setErrors(errorList);
      });
  }

  function handleSubmit() {
    if (validateForm(errors) || errors.post.length > 0) {
      setPriceLoader(true);
      let payload = {
        vehicleId: props.details.id,
        otp: otpValue,
        driveHomePrice: newPrice,
      };
      console.log(payload);
      reduceListedPrice(payload)
        .then((item) => {
          let errorList = errors;
          errorList.post = "";
          setErrors(errorList);
          setPriceLoader(false);
          props.handleClose();
          props.reloadList(true);
          let newData: any = payload;
          newData.url =
            process.env.REACT_APP_WEBFRONT_LINK +
            "/details/" +
            payload.vehicleId;
          newData.userData = props.userData;
          ActivityTracker("user_has_reduced_price", newData);
        })
        .catch((error) => {
          setPriceLoader(false);
          let errorList = errors;
          if (error.request.status == 0) {
            errorList.post = "uh oh ! No internet try again";
          } else {
            errorList.post = "uh oh ! Something went wrong, try again";
          }
          setErrors(errorList);
        });
    } else {
      console.error("Invalid Form");
    }
  }
  function handleChange(event: any) {
    event.preventDefault();
    const { name, value } = event.target;
    let errorList = errors;

    switch (name) {
      case "price":
        if (value <= 0) {
          errorList.price = "invalid price";
        } else {
          errorList.price =
            value > props.details.price.driveHomePrice
              ? "price cannot be greater than your current price"
              : "";
        }

        setNewPrice(value);
        break;
      case "otp":
        // errorList.otp = value.length != 4 ? "invalid OTP" : "";
        // setOTPValue(value);
        break;
    }
    setErrors(errorList);
    console.log(errorList);
  }
  function moveToNext(e: any) {
    let target = e.currentTarget;
    if (e.keyCode == 8 && target.previousSibling != null) {
      target.value = "";
      target.previousElementSibling.focus();
    } else if (
      (e.keyCode >= 48 && e.keyCode <= 57) ||
      (e.keyCode >= 96 && e.keyCode <= 105)
    ) {
      if (target.value.length == target.maxLength && target.id !== "4") {
        target.value = e.key;
        target.nextElementSibling.focus();
      }
    } else {
      target.value = "";
    }
    let otp = [
      otp1.current.value,
      otp2.current.value,
      otp3.current.value,
      otp4.current.value,
    ].join("");
    let errorList = errors;
    errorList.otp = otp.length != 4 ? "invalid otp" : "";
    console.log(otp);
    setErrors(errorList);
    setOTPValue(otp);
  }
  if (props.details.price) {
    return (
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={props.openModal}
        onClose={props.handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className="reduce-price-modal"
      >
        <DialogTitle>Reduce Price</DialogTitle>
        <DialogContent>
          <DialogContentText tabIndex={-1}>
            <form>
              <div className="content">
                <h6 className="title">
                  Your &nbsp; <b>{props.details.model}</b> &nbsp;is currently
                  priced at:
                </h6>
                <h2 className="title-huge">
                  {numberWithCommas(props.details.price.driveHomePrice)}
                </h2>
                <div className="price-container">
                  <span className="title">
                    <b>
                      Enter the new price you wish your car to be listed at:
                    </b>
                  </span>
                  <div className="d-flex">
                    <span className="title-huge rupee">₹</span>
                    <input
                      type="number"
                      name="price"
                      placeholder="eg. 100000"
                      onChange={handleChange}
                    />
                  </div>
                  {errors.price.length > 0 && (
                    <span className="error-message">{errors.price}</span>
                  )}
                </div>
                <div className="otp-container mt-3">
                  <div className="d-flex">
                    <div className="d-flex otpcode-list">
                      <input
                        type="text"
                        name="otpcode"
                        id="1"
                        ref={otp1}
                        maxLength={1}
                        pattern="^[0-9]*$"
                        inputMode="numeric"
                        onKeyUp={(element) => {
                          moveToNext(element);
                        }}
                      />
                      <input
                        type="text"
                        name="otpcode"
                        id="2"
                        ref={otp2}
                        maxLength={1}
                        pattern="[0-9]*"
                        inputMode="numeric"
                        onKeyUp={(element) => {
                          moveToNext(element);
                        }}
                      />
                      <input
                        type="text"
                        name="otpcode"
                        id="3"
                        ref={otp3}
                        maxLength={1}
                        pattern="[0-9]*"
                        inputMode="numeric"
                        onKeyUp={(element) => {
                          moveToNext(element);
                        }}
                      />
                      <input
                        type="text"
                        name="otpcode"
                        id="4"
                        ref={otp4}
                        maxLength={1}
                        pattern="[0-9]*"
                        inputMode="numeric"
                        onKeyUp={(element) => {
                          moveToNext(element);
                        }}
                      />
                    </div>
                    <span
                      className="btn sendotp-btn"
                      onClick={() => {
                        sendOtp();
                      }}
                    >
                      Send OTP
                      <svg
                        className="loader-svg"
                        version="1.1"
                        id="loader-1"
                        x="0px"
                        y="0px"
                        width="25px"
                        height="25px"
                        viewBox="0 0 50 50"
                        enableBackground="new 0 0 50 50"
                        display={otpLoader ? "inherit" : "none"}
                      >
                        <path
                          fill="#fff"
                          d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
                        >
                          <animateTransform
                            attributeType="xml"
                            attributeName="transform"
                            type="rotate"
                            from="0 25 25"
                            to="360 25 25"
                            dur="0.6s"
                            repeatCount="indefinite"
                          />
                        </path>
                      </svg>
                    </span>
                  </div>
                  {errors.otp.length > 0 && (
                    <span className="error-message">{errors.otp}</span>
                  )}
                </div>
                <div className="note mt-3">
                  <b>Please note:</b>
                  <ol>
                    <li>
                      Once you have proceeded with this reduction, you cannot
                      increase the price back to original listed price.
                    </li>
                    <li>
                      You will have to authenticate this action with an OTP sent
                      to your registered mobile number.
                    </li>
                  </ol>
                </div>
                <h3 className="text-purple">
                  Are you sure you wish to reduce listed price of your car?
                </h3>
              </div>
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="btn-primary-b p-2 px-3"
            disabled={
              otpRecieve && otpValue.length == 4 && validateForm(errors)
                ? false
                : true
            }
            onClick={() => {
              handleSubmit();
            }}
          >
            Yes, Proceed
          </Button>
          <Button type="submit" onClick={props.handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return <div></div>;
  }
}

const mapStateToProps = (state: any) => {
  return {
    userData: state.Login.userData,
  };
};
export default connect(mapStateToProps, subscriptionAction)(ReducePriceModal);
