import React, { useState, useEffect } from "react";
import WelcomePage from "./WelcomePage";
import CarsListing from "./CarsListing";

export default function SellerDashboard() {
  const [carsCount, setCarsCount] = useState(1);
  useEffect(() => {}, []);
  switch (carsCount) {
    case 0:
      return <WelcomePage />;
    case 1:
      return <CarsListing />;
    default:
      return <div>Seller Page</div>;
  }
}
