import axios from "axios";
import { AUTH_USER, AUTH_ERROR, ADD_USER_DATA, UPDATE_TOKEN } from "./types";

export function logout() {
  sessionStorage.setItem("auth", "false");
  return {
    type: AUTH_USER,
    payload: "",
  };
}

export function login() {
  sessionStorage.setItem("auth", "true");
  return {
    type: AUTH_USER,
    payload: true,
  };
}
export function saveLoginData(userData: any) {
  return {
    type: ADD_USER_DATA,
    payload: userData,
  };
}

export function updatedToken(token: any) {
  return {
    type: UPDATE_TOKEN,
    payload: token,
  };
}

