import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  useHistory,
  Switch,
  Route,
  useRouteMatch,
  Redirect,
} from "react-router-dom";
import TopNav from "../../shared/components/TopNav";
import SellerDashboard from "./sellerDashboard/SellerDashboard";
import { FAQs } from "./FAQs";
import { BuyACar } from "./buyCar/BuyACar";
import { SellACar } from "./sellCar/SellACar";

type Props = {
  authenticated: boolean;
};

function Dashboard(props: Props) {
  const history = useHistory();
  let { path, url } = useRouteMatch();

  useEffect(() => {
    if (!props.authenticated) {
      history.push("/login");
    }
  }, []);
  return (
    <div className="layout--dashboard">
      <TopNav />
      <div className="layout--main--section mx-auto">
        <Switch>
          <Route exact path={path}>
            <Redirect to={`/dashboard/dashboard`} />
          </Route>
          <Route exact path={`${path}/dashboard`} component={SellerDashboard} />
          <Route exact path={`${path}/sell`} component={SellACar} />
          <Route exact path={`${path}/buy`} component={BuyACar} />
          <Route exact path={`${path}/faqs`} component={FAQs} />
        </Switch>
      </div>
    </div>
  );
}
const mapStateToProps = (state: any) => {
  return {
    authenticated: state.Login.authenticated,
  };
};
export default connect(mapStateToProps)(Dashboard);
