import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogProps,
  DialogContentText,
  Button,
} from "@material-ui/core";
import { OnGoingSaleModel } from "shared/models/OnGoing";
import { numberWithCommas } from "shared/utils/formatStrings";
import { getDocuments } from "shared/services/vehicle";
import { emptyDocumentList, DocumentsModel } from "shared/models/Documents";

type Props = {
  openModal: boolean;
  details: OnGoingSaleModel;
  handleClose: any;
};

export function CarDetails(props: Props) {
  const [documentList, setDocumentList] =
    useState<DocumentsModel>(emptyDocumentList);

  useEffect(() => {
    setDocumentList(emptyDocumentList);
    fetchDocumentList();
  }, [props.details]);

  function fetchDocumentList() {
    if (props.details.id) {
      getDocuments(props.details.id).then((response) => {
        setDocumentList(response.data);
      });
    }
  }
  let rcNumber = props.details.rcDetails.registrationNumber
    ? props.details.rcDetails.registrationNumber.substring(0, 4)
    : "-";
  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      open={props.openModal}
      onClose={props.handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      className="car--details"
    >
      <DialogTitle>Car Details</DialogTitle>
      <DialogContent>
        <DialogContentText tabIndex={-1}>
          <div className="container">
            <div className="mb-5">
              <h6 className="title mb-0">Make & Model</h6>
              <h5 className="font-weight-bold">{`${props.details.make} ${props.details.model}`}</h5>
            </div>
            <div className="mb-5">
              <h6 className="title mb-0">Year of Registration</h6>
              <h5 className="font-weight-bold">
                {new Date(props.details.year).getFullYear()}
              </h5>
            </div>
            <div className="mb-5">
              <h6 className="title mb-0">Distance Covered </h6>
              <h5 className="font-weight-bold">
                {props.details.totalKms.user
                  ? numberWithCommas(props.details.totalKms.user) + "  km"
                  : "-"}{" "}
              </h5>
            </div>
            <div className="mb-5">
              <h6 className="title mb-0">Registration Number</h6>
              <h5 className="font-weight-bold text-uppercase">
                {rcNumber}XXXX
              </h5>
            </div>
            <div className="mb-5">
              <h6 className="title mb-0">Documents Submitted</h6>
              <div className="document-listing-container row flex-wrap m-0 mt-4">
                <div className="col-md-4 document-type-content">
                  <h6 className="sub-title text-center font-weight-bold">
                    Personal Documents
                  </h6>
                  <ul className="mt-3 p-0">
                    {documentList.personalDocuments.map(
                      (document: any, index: number) => {
                        return (
                          <li
                            className="d-flex align-items-baseline mb-2"
                            key={index}
                          >
                            <div hidden={!document.submitted} className="mr-3">
                              <img
                                src={require("../../assets/icons/icon-doc-completed.svg")}
                                alt=""
                              />
                            </div>
                            <div hidden={document.submitted} className="mr-3">
                              <img
                                src={require("../../assets/icons/icon-doc-pending.svg")}
                                alt=""
                              />
                            </div>
                            <h6 className="doc-name">{document.name}</h6>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
                <div className="col-md-4 document-type-content">
                  <h6 className="sub-title text-center font-weight-bold">
                    Cars Document
                  </h6>
                  <ul className="mt-3 p-0">
                    {documentList.carDocuments.map(
                      (document: any, index: number) => {
                        return (
                          <li
                            className="d-flex align-items-baseline mb-2"
                            key={index}
                          >
                            <div hidden={!document.submitted} className="mr-3">
                              <img
                                src={require("../../assets/icons/icon-doc-completed.svg")}
                                alt=""
                              />
                            </div>
                            <div hidden={document.submitted} className="mr-3">
                              <img
                                src={require("../../assets/icons/icon-doc-pending.svg")}
                                alt=""
                              />
                            </div>
                            <h6 className="doc-name">{document.name}</h6>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
                <div className="col-md-4 document-type-content">
                  <h6 className="sub-title text-center font-weight-bold">
                    Car Extras
                  </h6>
                  <ul className="mt-3 p-0">
                    {documentList.carExtras.map(
                      (document: any, index: number) => {
                        return (
                          <li
                            className="d-flex align-items-baseline mb-2"
                            key={index}
                          >
                            <div hidden={!document.submitted} className="mr-3">
                              <img
                                src={require("../../assets/icons/icon-doc-completed.svg")}
                                alt=""
                              />
                            </div>
                            <div hidden={document.submitted} className="mr-3">
                              <img
                                src={require("../../assets/icons/icon-doc-pending.svg")}
                                alt=""
                              />
                            </div>
                            <h6 className="doc-name">{document.name}</h6>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className="btn-primary-b p-2 px-3"
          href={`${process.env.REACT_APP_WEBFRONT_LINK}usedcars/${
            props.details.make.replace(/\s+/g, "-").toLowerCase() +
            "/" +
            props.details.model.replace(/\s+/g, "-").toLowerCase() +
            "/" +
            props.details.id
          }`}
          //href={`${process.env.REACT_APP_WEBFRONT_LINK}/details/${props.details.id}`}
          target="_blank"
          hidden={
            props.details.status.toLowerCase() != "lead" &&
            props.details.sellingFormat.toLowerCase() != "auction"
              ? false
              : true
          }
        >
          View Listing on site
        </Button>
        <Button onClick={props.handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
