export interface Color {
  type: string;
  code: string;
  rcColor: string;
}

export interface Feature {
  featureId: string;
  featureName: string;
}

export interface Handlers {
  seniorDost: string;
  inspectionDost: string;
  inventoryDost: string;
  rfDost: string;
}

export interface Videos {
  seller: string;
  expert: string;
}

export interface Specifications {
  length: number;
  width: number;
  height: number;
  engineDisplacement: number;
}

export interface Dates {
  lead: string;
  inspection: string;
  pickUpStart: string;
  pickUpEnd: string;
  refurbishmentStart: string;
  refurbishmentEnd: string;
  listingStart: string;
  listingEnd: string;
  documentStart: string;
  documentEnd: string;
}

export interface TotalKms {
  user: number;
  inspection: number;
}

export interface RegisteredOwner {
  name: string;
  address: string;
}

export interface RcDetails {
  registrationNumber: string;
  registrationDate: string;
  chassisNumber: string;
  engineNumber: string;
}

export interface AgreedCharge {
  particular: string;
  cost: number;
}

export interface RefusedCharge {
  particular: string;
  cost: number;
}

export interface Price {
  auctionPrice: number;
  driveHomePrice: number;
  gigacarFees: number;
  agreedCharges: number;
  bodyWorks: number;
  documentationCharges: number;
  extraCharges: number;
  gcCashback: number;
  gcSuccessFee: number;
  insurance: number;
  listingFee: number;
  netPayableSellerPrice: number;
  netPurchasePrice: number;
  sellerFinalPrice: number;
  trafficFines:number
}

export interface Count {
  favourite: number;
  view: number;
  testDrive: number;
}

export interface OnGoingSaleModel {
  id: string;
  gigaCode: string;
  model: string;
  thumbnail: string[];
  threeDViewerId: string;
  color: Color;
  hubLocation: string;
  features: Feature[];
  handlers: Handlers;
  videos: Videos;
  specifications: Specifications;
  dates: Dates;
  customer: string;
  numberOfOwners: number;
  totalKms: TotalKms;
  year: string;
  status: string;
  refurbishmentStatus: boolean;
  registeredOwner: RegisteredOwner;
  rcDetails: RcDetails;
  price: Price;
  sellingFormat: string;
  corporate: string;
  count: Count;
  relatedModel: string;
  warranty: boolean;
  inventory: string[];
  created: string;
  updated: string;
  make: string;
  expectedPrice?: number;
  fuelType: string;
  auction: {
    auctionPrice: number;
    driveHomePrice: number;
    gigacarFees: number;
    agreedCharges: number;
    bodyWorks: number;
    documentationCharges: number;
    extraCharges: number;
    gcCashback: number;
    gcSuccessFee: number;
    insurance: number;
    listingFee: number;
    netPayableSellerPrice: number;
    netPurchasePrice: number;
    sellerFinalPrice: number;
  };
}

export const emptyOnGoingSale = {
  id: "",
  gigaCode: "",
  model: "",
  thumbnail: [],
  threeDViewerId: "",
  color: {},
  hubLocation: "",
  features: [],
  handlers: {},
  videos: {},
  specifications: {},
  dates: {},
  customer: "",
  numberOfOwners: 0,
  totalKms: "",
  year: "",
  status: "",
  refurbishmentStatus: false,
  registeredOwner: {},
  rcDetails: {},
  price: {},
  sellingFormat: "",
  corporate: "",
  count: {},
  relatedModel: "",
  warranty: false,
  inventory: [],
  created: "",
  updated: "",
  make: "",
  expectedPrice: 0,
  auction: {},
};
