import axios from "axios";
import store from "redux/store";
const baseURL = process.env.REACT_APP_API_VEHICLE;
const apikey = process.env.REACT_APP_API_KEY_VEHICLE;
const apiSecret = process.env.REACT_APP_API_SECRET_VEHICLE;

let activityQueue: any[] = [];
let activeRequest: boolean = false;
const config = {
  headers: {
    Authorization: "",
    "Content-Type": "application/json",
    "api-key": apikey,
    "api-secret": apiSecret
  }
};
function getToken() {
  let state = store.getState();
  let authToken = state.Login.authToken;
  return authToken;
}
export function ActivityTracker(
  event: string,
  parameter: any,
  vehicleId?: string
) {
  let payload = {
    event: event,
    parameter: parameter,
    source: "web",
    vehicleId: vehicleId
    //customerId: authHeader()
  };
  activityQueue.push(payload);
  makeRequestsFromArray(activityQueue);
}
function makeRequestsFromArray(arr: any[]) {
  if (activeRequest || arr.length == 0) {
    return;
  }
  let url = `${baseURL}/customerActivity`;
  activeRequest = true;
  console.log(navigator.onLine, "Customer Activity => ", arr[0].event);
  config.headers.Authorization = getToken();
  axios
    .post(url, arr[0], config)
    .then(() => {
      arr.shift();
      activeRequest = false;
    })
    .catch(error => {
      activeRequest = false;
      if (error.request.status == 0) {
        setTimeout(() => {
          makeRequestsFromArray(activityQueue);
        }, 30000);
      }
    });
  makeRequestsFromArray(activityQueue);
}

//user_added_car
//user_has_logged_in
//user_has_reduced_price
//sell_at_offer_price

