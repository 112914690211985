//Login
export const AUTH_USER = "auth_user";
export const AUTH_ERROR = "auth_error";
export const ADD_USER_DATA = "add_user_data";
export const UPDATE_TOKEN = "update_token";
// export const UPDATE_CORPORATE_USER = "update_corporate_user";

//subscriptions
export const ADDED_NEW_CAR = "added_new_car";
export const ONGOING_DATA = "ongoing_data";
