import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import store from "redux/store";
import "bootstrap/dist/css/bootstrap.min.css";
import { login, logout } from "redux/actions/login";

function App() {
  let authenticated = sessionStorage.getItem("auth");
  if (authenticated == "true") {
    store.dispatch(login());
  } else {
    store.dispatch(logout());
  }

  // sample commit
  // sample commit
// sample commit
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/login" component={Login} />
          <Route path="/dashboard" component={Dashboard} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
