import {
  AUTH_USER,
  AUTH_ERROR,
  ADD_USER_DATA,
  UPDATE_TOKEN,
} from "../actions/types";

export interface ILoginState {
  authenticated: boolean;
  errorMessage: string;
  authToken: string;
  userData: any;
  corporateUserData: any;
}

const initialState: ILoginState = {
  authenticated: false,
  errorMessage: "",
  userData: userInfo(),
  authToken: authHeader(),
  corporateUserData: {},
};

function LoginReducer(
  state: ILoginState = initialState,
  action: any
): ILoginState {
  switch (action.type) {
    case AUTH_USER:
      return { ...state, authenticated: action.payload };
    case AUTH_ERROR:
      return { ...state, errorMessage: action.payload };
    case ADD_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case UPDATE_TOKEN:
      return {
        ...state,
        authToken: action.payload,
      };
    default:
      return state;
  }
}
export default LoginReducer;

export function authHeader() {
  let storeuser = sessionStorage.getItem("user") || "";
  if (storeuser) {
    let user = JSON.parse(storeuser);
    if (user && user.token) {
      return user.token;
    } else {
      return "";
    }
  } else {
    return "";
  }
}

export function userInfo() {
  let storeuser = sessionStorage.getItem("user") || "";
  if (storeuser) {
    let user = JSON.parse(storeuser);
    if (user) {
      return user;
    } else {
      return "";
    }
  } else {
    return "";
  }
}
