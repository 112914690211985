import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Snackbar,
  DialogActions,
  Button,
} from "@material-ui/core";
import { OnGoingSaleModel } from "shared/models/OnGoing";
import { sendOTP } from "shared/services/customer";
import { Alert } from "@material-ui/lab";
import { sellAtOfferPrice } from "shared/services/vehicle";
import { connect } from "react-redux";
import * as subscriptionAction from "redux/actions/subscriptions";
import { numberWithCommas } from "shared/utils/formatStrings";
import { ActivityTracker } from "shared/services/activityTracker";

type Props = {
  openModal: boolean;
  details: OnGoingSaleModel;
  handleClose: any;
  reloadList: any;
  userData: any;
};

function SellAtOfferPrice(props: Props) {
  const [otpRecieve, setOTPRecieved] = useState(false);
  const [otpRecieveAlert, setOTPRecievedAlert] = useState(false);
  const [otpValue, setOTPValue] = useState("");
  const [errors, setErrors] = useState({
    price: "",
    otp: "",
    post: "",
  });
  let otp1: any = React.createRef();
  let otp2: any = React.createRef();
  let otp3: any = React.createRef();
  let otp4: any = React.createRef();
  const [otpLoader, setOtpLoader] = useState(false);
  const [soldAtOfferPrice, setSoldAtOfferPrice] = useState(false);
  const [erroAlert, setErrorAlert] = useState(false);
  let finalPrice;
  function moveToNext(e: any) {
    let target = e.currentTarget;
    if (e.keyCode == 8 && target.previousSibling != null) {
      target.value = "";
      target.previousElementSibling.focus();
    } else if (
      (e.keyCode >= 48 && e.keyCode <= 57) ||
      (e.keyCode >= 96 && e.keyCode <= 105)
    ) {
      if (target.value.length == target.maxLength && target.id !== "4") {
        target.value = e.key;
        target.nextElementSibling.focus();
      }
    } else {
      target.value = "";
    }
    let otp = [
      otp1.current.value,
      otp2.current.value,
      otp3.current.value,
      otp4.current.value,
    ].join("");
    let errorList = errors;
    errorList.otp = otp.length != 4 ? "invalid otp" : "";
    console.log(otp);
    setErrors(errorList);
    setOTPValue(otp);
  }

  function handleSubmit() {
    let payload = {
      vehicle: props.details.id,
      otp: otpValue,
      accepted: true,
    };

    sellAtOfferPrice(payload)
      .then((response) => {
        setSoldAtOfferPrice(true);
        props.handleClose();
        setTimeout(() => {
          setSoldAtOfferPrice(false);
          props.reloadList(true);
        }, 3000);

        let newData: any = payload;
        newData.price = props.details.price.driveHomePrice;
        newData.userData = props.userData;
        ActivityTracker("sell_at_offer_price", payload);
      })
      .catch(() => {
        setErrorAlert(true);
        setTimeout(() => {
          setErrorAlert(false);
        }, 3000);
      });
  }

  function sendOtp() {
    setOtpLoader(true);
    sendOTP()
      .then(() => {
        let errorList = errors;
        errorList.otp = "";
        setErrors(errorList);
        setOtpLoader(false);
        setOTPRecieved(true);
        setOTPRecievedAlert(true);
        setTimeout(() => {
          setOTPRecievedAlert(false);
        }, 3000);
      })
      .catch((error) => {
        //TODO:if something wrong show message

        console.log(error);
        setOtpLoader(false);
        let errorList = errors;
        if (error.request.status == 0) {
          errorList.otp = "uh oh ! No internet try again";
        } else {
          errorList.otp = "uh oh ! Something went wrong, try again";
        }
        setErrors(errorList);
        setErrorAlert(true);
        setTimeout(() => {
          setErrorAlert(false);
        }, 3000);
      });
  }
  if (
    props.details.sellingFormat.toLowerCase() == "auction" &&
    props.details.auction
  ) {
    finalPrice = props.details.auction.driveHomePrice
      ? numberWithCommas(props.details.auction.driveHomePrice)
      : "-";
  }
  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={props.openModal}
      onClose={props.handleClose}
      scroll="paper"
      className="reduce-price-modal"
    >
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={otpRecieveAlert}
      >
        <Alert severity="info">OTP has been sent!</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={soldAtOfferPrice}
      >
        <Alert severity="success">Sucessfully accepted offer price !</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={erroAlert}
      >
        <Alert severity="error">Oops, something went wrong!</Alert>
      </Snackbar>
      <DialogTitle>Sell at Offer Price</DialogTitle>
      <DialogContent>
        <DialogContentText tabIndex={-1}>
          <div className="container">
            <div className="otp-container mt-3 mx-0">
              <div className="d-flex">
                <div className="d-flex otpcode-list">
                  <input
                    type="text"
                    name="otpcode"
                    id="1"
                    ref={otp1}
                    maxLength={1}
                    pattern="^[0-9]*$"
                    inputMode="numeric"
                    onKeyUp={(element) => {
                      moveToNext(element);
                    }}
                  />
                  <input
                    type="text"
                    name="otpcode"
                    id="2"
                    ref={otp2}
                    maxLength={1}
                    pattern="[0-9]*"
                    inputMode="numeric"
                    onKeyUp={(element) => {
                      moveToNext(element);
                    }}
                  />
                  <input
                    type="text"
                    name="otpcode"
                    id="3"
                    ref={otp3}
                    maxLength={1}
                    pattern="[0-9]*"
                    inputMode="numeric"
                    onKeyUp={(element) => {
                      moveToNext(element);
                    }}
                  />
                  <input
                    type="text"
                    name="otpcode"
                    id="4"
                    ref={otp4}
                    maxLength={1}
                    pattern="[0-9]*"
                    inputMode="numeric"
                    onKeyUp={(element) => {
                      moveToNext(element);
                    }}
                  />
                </div>
                <span
                  className="btn sendotp-btn"
                  onClick={() => {
                    sendOtp();
                  }}
                >
                  Send OTP
                  <svg
                    className="loader-svg"
                    version="1.1"
                    id="loader-1"
                    x="0px"
                    y="0px"
                    width="25px"
                    height="25px"
                    viewBox="0 0 50 50"
                    enableBackground="new 0 0 50 50"
                    display={otpLoader ? "inherit" : "none"}
                  >
                    <path
                      fill="#fff"
                      d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
                    >
                      <animateTransform
                        attributeType="xml"
                        attributeName="transform"
                        type="rotate"
                        from="0 25 25"
                        to="360 25 25"
                        dur="0.6s"
                        repeatCount="indefinite"
                      />
                    </path>
                  </svg>
                </span>
              </div>
              {errors.otp.length > 0 && (
                <span className="error-message">{errors.otp}</span>
              )}
            </div>

            <h5 className="text-purple mt-5">
              Are you sure you want to sell{" "}
              {`${props.details.make} ${props.details.model}`} at{" "}
              <b>₹ {finalPrice}</b> ?
            </h5>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="py-3">
        <Button
          className="btn-primary-b p-2 px-3"
          disabled={otpRecieve && otpValue.length == 4 ? false : true}
          onClick={() => {
            handleSubmit();
          }}
        >
          Yes, Proceed
        </Button>
        <Button type="submit" onClick={props.handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userData: state.Login.userData,
  };
};

export default connect(mapStateToProps, subscriptionAction)(SellAtOfferPrice);
