interface PersonalDocument {
  name: string;
  submitted: boolean;
  mandatory: boolean;
  url: string;
}

interface CarDocument {
  name: string;
  submitted: boolean;
  mandatory: boolean;
  url: string;
}

interface CarExtra {
  name: string;
  submitted: boolean;
  mandatory: boolean;
  url: string;
}

export interface DocumentsModel {
  _id: string;
  vehicle: string;
  personalDocuments: PersonalDocument[];
  carDocuments: CarDocument[];
  carExtras: CarExtra[];
}

export const emptyDocumentList: DocumentsModel = {
    _id: "",
    carDocuments:[],
    carExtras:[],
    personalDocuments:[],
    vehicle: ""
}