import React, { useState, useEffect } from "react";
import WelcomePage from "./WelcomePage";
import { AppBar, Tabs, Tab, Box, Typography, Paper } from "@material-ui/core";
import OnGoingSale from "./OnGoingSale";
import RejectedQuotes from "./RejectedQuotes";
import SellingHistory from "./SellingHistory";
import { getOnGoingvehicles } from "shared/services/vehicle";
import { connect } from "react-redux";
import * as subscriptionAction from "redux/actions/subscriptions";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function CarsListing() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <div className="layout--cars--listing pb-5 px-3">
      <WelcomePage />
      <Paper className="layout--tabs">
        <Tabs value={value} onChange={handleChange}>
          <Tab className="mr-4" label="Ongoing Sale" {...a11yProps(0)} />
          <Tab className="mr-4" label="Selling History" {...a11yProps(1)} />
          <Tab className="mr-4" label="Rejected Quotes" {...a11yProps(2)} />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <OnGoingSale />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SellingHistory />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <RejectedQuotes />
      </TabPanel>
    </div>
  );

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Typography>{children}</Typography>}
      </div>
    );
  }
}

export default connect(null, subscriptionAction)(CarsListing);
