import XLSX from "xlsx";
import { saveAs } from "file-saver";
import { OnGoingSaleModel } from "shared/models/OnGoing";
import { numberWithCommas } from "./formatStrings";

export default function createXLData(data: any, sheetName: string) {
  var wb = XLSX.utils.book_new();
  let currentDate = new Date();
  wb.Props = {
    Title:
      currentDate.getDate() +
      "/" +
      (currentDate.getMonth() + 1) +
      "/" +
      currentDate.getFullYear() +
      " " +
      sheetName,
    Subject: sheetName,
    Author: "Giga Cars",
    CreatedDate: currentDate,
  };

  wb.SheetNames.push(sheetName);
  var ws_data = getDataInArray(data, sheetName);
  var ws = XLSX.utils.aoa_to_sheet(ws_data);
  wb.Sheets[sheetName] = ws;
  var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
  saveAs(
    new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
    `${
      currentDate.getDate() +
      "/" +
      (currentDate.getMonth() + 1) +
      "/" +
      currentDate.getFullYear() +
      " " +
      sheetName
    }.xlsx`
  );
}
function s2ab(s: any) {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}
let arr1: any[] = [
  [
    "#",
    "Make & Model",
    "Registration Number",
    "Year of Registration",
    "Selling Format",
    "Status Of Car",
    "Likes",
    "Views",
    "Test Drive",
    "Added On",
    "Sale Value",
  ],
];
let arr2: any[] = [
  [
    "#",
    "Make & Model",
    "Registration Number",
    "Year of Registration",
    "Selling Format",
    "Status Of Car",
    "Likes",
    "Views",
    "Test Drive",
    "Added On",
    "Sold On",
    "Sale Value",
    "Payment Status",
    "RC Transfer Status",
  ],
];
let arr3: any[] = [
  [
    "#",
    "Make & Model",
    "Registration Number",
    "Year of Registration",
    "Selling Format",
    "Status Of Car",
    "Added On",
    "Rejected On",
    "Expected Price",
    "Offer Price",
    "Status of car",
  ],
];
function getDataInArray(data: any, sheetName: string) {
  switch (sheetName) {
    case "OnGoing Sale List":
      data
        .sort(
          (a: any, b: any) =>
            new Date(b.created).valueOf() - new Date(a.created).valueOf()
        )
        .forEach((item: OnGoingSaleModel, index: number) => {
          let price: any = "-";
          let options: any = {
            year: "numeric",
            month: "long",
            day: "numeric",
          };
          if (item.sellingFormat.toLowerCase() === "auction" && item.auction) {
            price = item.auction.driveHomePrice
              ? numberWithCommas(item.auction.driveHomePrice)
              : "-";
          } else if (item.sellingFormat != "auction" && item.price) {
            price = item.price.driveHomePrice
              ? numberWithCommas(item.price.driveHomePrice)
              : "-";
          }
          let date = new Date(item.created).toLocaleDateString(
            "en-US",
            options
          );
          let rcDate = item.rcDetails.registrationDate
            ? new Date(item.created).toLocaleDateString("en-US", options)
            : "-";
          let tempArr = [
            index + 1,
            item.make.toLowerCase() + " " + item.model.toLowerCase(),
            item.rcDetails.registrationNumber
              ? item.rcDetails.registrationNumber
              : "-",
            rcDate,
            item.sellingFormat === "auction"
              ? item.sellingFormat
              : "Park & Sell",
            item.status.split("_").join(" "),
            item.count.favourite ? item.count.favourite : 0,
            item.count.view ? item.count.view : 0,
            item.count.testDrive ? item.count.testDrive : 0,
            date,
            price,
          ];
          arr1.push(tempArr);
        });
      return arr1;
    case "Sold Car History":
      data
        .sort(
          (a: any, b: any) =>
            new Date(b.created).valueOf() - new Date(a.created).valueOf()
        )
        .forEach((item: any, index: number) => {
          let price: any = "-";
          let options: any = {
            year: "numeric",
            month: "long",
            day: "numeric",
          };
          if (item.sellingFormat.toLowerCase() === "auction" && item.auction) {
            price = item.auction.driveHomePrice
              ? numberWithCommas(item.auction.driveHomePrice)
              : "-";
          } else if (item.sellingFormat != "auction" && item.price) {
            price = item.price.driveHomePrice
              ? numberWithCommas(item.price.driveHomePrice)
              : "-";
          }
          let dateCreated = new Date(item.created).toLocaleDateString(
            "en-US",
            options
          );
          let datePurchase = new Date(
            item.dates.purchaseStart
          ).toLocaleDateString("en-US", options);

          let rcDate = item.rcDetails.registrationDate
            ? new Date(item.created).toLocaleDateString("en-US", options)
            : "-";
          let tempArr = [
            index + 1,
            item.make.toLowerCase() + " " + item.model.toLowerCase(),
            item.rcDetails.registrationNumber
              ? item.rcDetails.registrationNumber
              : "-",
            rcDate,
            item.sellingFormat === "auction"
              ? item.sellingFormat
              : "Park & Sell",
            item.status.split("_").join(" "),
            item.count.favourite ? item.count.favourite : 0,
            item.count.view ? item.count.view : 0,
            item.count.testDrive ? item.count.testDrive : 0,
            dateCreated,
            datePurchase,
            price,
            item.paymentStatus.split("_").join(" "),
            item.rcTransferStatus.split("_").join(" "),
          ];
          arr2.push(tempArr);
        });
      return arr2;
    case "Rejected Quotes":
      data
        .sort(
          (a: any, b: any) =>
            new Date(b.created).valueOf() - new Date(a.created).valueOf()
        )
        .forEach((item: any, index: number) => {
          let price: any = "-";
          let options: any = {
            year: "numeric",
            month: "long",
            day: "numeric",
          };
          if (item.sellingFormat.toLowerCase() === "auction" && item.auction) {
            price = item.auction.driveHomePrice
              ? numberWithCommas(item.auction.driveHomePrice)
              : "-";
          } else if (item.sellingFormat != "auction" && item.price) {
            price = item.price.driveHomePrice
              ? numberWithCommas(item.price.driveHomePrice)
              : "-";
          }
          let dateCreated = new Date(item.created).toLocaleDateString(
            "en-US",
            options
          );
          let dateRejected = new Date(
            item.dates.rejectionDate
          ).toLocaleDateString("en-US", options);

          let rcDate = item.rcDetails.registrationDate
            ? new Date(item.created).toLocaleDateString("en-US", options)
            : "-";
          let tempArr = [
            index + 1,
            item.make.toLowerCase() + " " + item.model.toLowerCase(),
            item.rcDetails.registrationNumber
              ? item.rcDetails.registrationNumber
              : "-",
            rcDate,
            item.sellingFormat === "auction"
              ? item.sellingFormat
              : "Park & Sell",
            item.status.split("_").join(" "),
            dateCreated,
            dateRejected,
            numberWithCommas(item.expectedPrice),
            price,
            item.paymentStatus.split("_").join(" "),
          ];
          arr3.push(tempArr);
        });
      return arr3;
    default:
      return arr1;
  }
}
