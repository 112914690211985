import axios from "axios";
import store from "redux/store";

type loginModel = {
  phoneNumber: string;
};
type RegiserModel = {
  phoneNumber: string;
  role: number;
  firstName: string;
  emailId: string;
};
type OTPModel = {
  phoneNumber: string;
  otp: number;
};

const baseURL = process.env.REACT_APP_API_CUSTOMER;
const apikey = process.env.REACT_APP_API_KEY_CUSTOMER;
const apiSecret = process.env.REACT_APP_API_SECRET_CUSTOMER;
const config = {
  headers: {
    "Content-Type": "application/json",
    "api-key": apikey,
    "api-secret": apiSecret,
    Authorization: "",
  },
};

function getToken() {
  let state = store.getState();
  let authToken = state.Login.authToken;
  return authToken;
}

export function loginUser(phoneNumber: string) {
  let payload: loginModel = {
    phoneNumber: phoneNumber,
  };

  return axios.post(`${baseURL}/login`, payload, config).then((response) => {
    console.log(response.data);
  });
}

export function verifyOTP(
  phoneNumber: string,
  OTP: number,
  customerAuthId: string
) {
  let payload = {
    phoneNumber: phoneNumber,
    otp: OTP,
    customerOTPAuthorizationId: customerAuthId,
  };
  return axios.post(`${baseURL}/checkOTP`, payload, config);
}

export function sendOTP() {
  config.headers.Authorization = getToken();
  return axios.get(`${baseURL}/sendOTP`, config);
}

export function corporateLogin(phoneNumber: string) {
  let payload: loginModel = {
    phoneNumber: phoneNumber,
  };
  return axios.post(`${baseURL}/corporateLogin`, payload, config);
}
