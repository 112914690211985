import { ADDED_NEW_CAR, ONGOING_DATA } from "redux/actions/types";

export interface ISubscription {
  addedNewCar: boolean;
  onGoingData: any;
}
const initialState: ISubscription = {
  addedNewCar: false,
  onGoingData: {},
};

export default function SubscriptionsReducer(
  state: ISubscription = initialState,
  action: any
): ISubscription {
  switch (action.type) {
    case ADDED_NEW_CAR:
      return { ...state, addedNewCar: action.payload };
    case ONGOING_DATA:
      return { ...state, onGoingData: action.payload };
    default:
      return state;
  }
}
