export function compressFile(file: any, callback_: any) {
  debugger;
  switch (file.type) {
    case "image/jpeg":
    case "image/jpg":
    case "image/png":
      if (Math.round(file.size / 1024 / 1024) > 1) {
        let loadedData = new Image();
        var imgwidth = 0;
        var imgheight = 0;
        loadedData.src = URL.createObjectURL(file);
        loadedData.onload = (e: any) => {
          imgwidth = e.currentTarget.width;
          imgheight = e.currentTarget.height;
          var quality = 20;
          var time_start = new Date().getTime();
          var mime_type = "image/jpeg";
          var cvs: any = document.createElement("canvas");
          cvs.width = Math.round(loadedData.width / 2);
          cvs.height = Math.round(loadedData.height / 2);
          var ctx = cvs.getContext("2d").drawImage(loadedData, 0, 0);
          var newImageData = cvs.toDataURL(mime_type, quality / 100);
          var duration = new Date().getTime() - time_start;
          console.log("Processed in: " + duration + "ms");
          var file = dataURItoBlob(newImageData);
          alert(file.size / 1024 / 1024);
          callback_(file);
        };
        break;
      } else {
        callback_(file);
        break;
      }
    case "application/pdf":
      callback_(file);
      break;
  }
}

function dataURItoBlob(dataURI: any) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);
  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
}
